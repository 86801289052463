.sign-in-card-inner {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, 0.500);
    /* width: 60vh; */
  }
  
  .login-button{
    background-color: rgb(6, 6, 109);
    color: white;
  }
  
  .card-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* height: 80vh; */
     /* width: 280vh; */
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .navigation-button {
    background-color: rgb(6, 6, 109);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .navigation-button:hover {
    background-color: rgb(8, 53, 175);
    color: white;
  }

  .navigation-button:active {
    background-color: rgb(8, 53, 175);
    color: white;
  }
  
  .navigation-button:disabled {
    background-color: rgb(6, 6, 109);
    color: white;
  }
  
  .custom-step-icon {
    color: rgb(8, 53, 175) !important;
  }
  
  .custom-step-icon {
    color: rgb(8, 53, 175) !important;
  }

  .error-step-icon {
    color: red !important;
  }

  .completed-step-icon {
    color: green !important;
  }