body {
  margin: 20;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: aqua; */
 
}

/* body.login-page{
  background-image: url("./utils/images/background_landingpage.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(8px);
  
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.amplify-button[data-variation='primary'] {
  background-color: rgb(6, 6, 109);
  color: white;
}

/* for animated blinking logo */
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* for animated blinking logo */
.logoblinking {
  width: 10vw;
  height: 20vh;
  border-radius: 80%;
  background-size: cover;
  /* background-color: #2196f3; */
  background-image: url('../public/images/loadinglogo.png');
  animation: blinkingEffect 1.5s ease-in-out infinite;
}
/* for animated blinking logo */
@keyframes blinkingEffect {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

/* Background Image for Sign in and Create Account */
/*  pseudo-element for the slideshow effect */
.blur-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: blur(8px); /* Apply the blur effect to the pseudo-element */
  z-index: -1; /* Send the pseudo-element to the back, behind the content */
  animation: slideshow 30s infinite; /* Adjust the animation duration as needed */
}

@keyframes slideshow {
  0%, 100% {
    background-image: url("../public/images/truck.jpg"); /* Set the initial background image */
  }
  20% {
    background-image: url("../public/images/refuse.jpg"); /* Set the second background image */
  }
  40% {
    background-image: url("../public/images/StraightTruck.jpg"); /* Set the third background image */
  }
  60% {
    background-image: url("../public/images/WorkTruck.jpg"); /* Set the fourth background image */
  }
  80% {
    background-image: url("../public/images/VacTruck.jpg"); /* Set the fifth background image */
  }
}

