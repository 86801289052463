/* single-client-details-page.css */
.container {
    max-width: 100%;
    max-height: 100%;
  }
  
  .table-container {
    overflow-x: auto;
    max-width: 100%;
    max-height: 100%;
  }
  
  /* Add media query for smaller screen sizes */
  @media (max-width: 600px) {
    .table-container {
      overflow-x: auto;
    }
  }
  
  /* Set table width to be responsive */
  table {
    width: 100%;
    table-layout: fixed;
    height: 100%;
  }

  .map-container {
    width: 100%;
    height: 400px;
  }
  
  
  /* Adjust column widths if necessary */
  th {
    /* Add appropriate column widths here */
  }
  