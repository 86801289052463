#more-details:hover {
  cursor: pointer;
}

#map-backdrop-header-spacer {
  height: 64;
}

.mapboxgl-ctrl-geocoder {
  width: 400px;
}

.maplibregl-ctrl-top-right {
  display: flex;
  flex-direction: row
}

.custom-map-control-button.MuiButton-root:not(:disabled):hover {
  background-color: #01579b
}

.custom-map-control-button-back.MuiButton-root:not(:disabled):hover {
  background-color: rgb(179, 0, 0)
}